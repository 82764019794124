import React from 'react';
import { Heading } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import {
  isBoatListingType,
  isJetSkiListingType,
  isFishingListingType,
  isWatersportListingType,
} from '../../util/types';
import css from './ListingPage.module.css';

const SectionRulesAndPolicyMaybe = props => {
  const { publicData, intl, listingCategory } = props;

  if (!publicData?.listingRules && !publicData?.listingCancellationPolicy) {
    return null;
  }

  const isBoatListing = isBoatListingType(listingCategory);
  const isJetSkiListing = isJetSkiListingType(listingCategory);
  const isFishingListing = isFishingListingType(listingCategory);
  const isWatersportListing = isWatersportListingType(listingCategory);

  const listingRules = !!publicData?.listingRules && (
    <div className={css.text}>
      <Heading as="h3" rootClassName={css.textLabel}>
        <FormattedMessage
          id={
            isBoatListing ? "ListingPage.rulesBoat" :
              isJetSkiListing ? "ListingPage.rulesJetSki" :
                isFishingListing ? "ListingPage.rulesFishing" :
                  isWatersportListing ? "ListingPage.rulesWatersport" :
                    "ListingPage.rules"
          }
        />
      </Heading>
      <p className={css.textDescription}>
        {publicData?.listingRules}
      </p>
    </div>
  )

  const flexible = intl.formatMessage({ id: 'CancellationPolicyOptions.flexible' });
  const moderate = intl.formatMessage({ id: 'CancellationPolicyOptions.moderate' });
  const strict = intl.formatMessage({ id: 'CancellationPolicyOptions.strict' });

  let cancellationPolicy;

  switch (publicData?.listingCancellationPolicy) {
    case 'flexible': {
      cancellationPolicy = flexible
      break;
    }
    case 'moderate': {
      cancellationPolicy = moderate
      break;
    }
    case 'strict': {
      cancellationPolicy = strict
      break;
    }
    default:
      cancellationPolicy = null
  }

  const listingCancellationPolicy = !!cancellationPolicy && (
    <div className={css.text}>
      <Heading as="h3" rootClassName={css.textLabel}>
        <FormattedMessage
          id={
            isBoatListing ? "ListingPage.cancellationPolicyBoat" :
              isJetSkiListing ? "ListingPage.cancellationPolicyJetSki" :
                isFishingListing ? "ListingPage.cancellationPolicyFishing" :
                  isWatersportListing ? "ListingPage.cancellationPolicyWatersport" :
                    "ListingPage.cancellationPolicy"
          }
        />
      </Heading>
      <p className={css.textDescription}>
        {cancellationPolicy}
      </p>
    </div>
  )

  return (
    <div className={css.sectionRulesAndPolicy}>
      {listingRules}
      {listingCancellationPolicy}
    </div>
  )
};

export default SectionRulesAndPolicyMaybe;
