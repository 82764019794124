import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionMainTextMaybe = props => {
  const { text, heading, showAsIngress = false, mainInfo } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  return text ? (
    <div className={css.sectionText}>

      {heading ? (
        <Heading as="h1" rootClassName={css.sectionHeadingTitle}>
          {heading}
        </Heading>
      ) : null}

      <div className={textClass}>
        {mainInfo && (
          <Heading as="h2" rootClassName={css.textLabel}>
            <FormattedMessage id="ListingPage.about" />
          </Heading>
        )}
        <p className={css.textDescription}>
          {richText(text, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </p>
      </div>
    </div>
  ) : null;
};

export default SectionMainTextMaybe;
