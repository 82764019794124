import React from 'react';
import { Heading, PropertyGroup } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import {
  isBoatListingType,
  isJetSkiListingType,
  isFishingListingType,
} from '../../util/types';
import css from './ListingPage.module.css';

const SectionAmenitiesMaybe = props => {
  const { publicData, listingConfig, listingCategory } = props;

  if (
    !publicData?.listingAmenitiesBoat?.length &&
    !publicData?.listingAmenitiesJetSki?.length &&
    !publicData?.listingAmenitiesFishing?.length
  ) {
    return null;
  }
  

  const amenitiesOptions = listingConfig?.listingFields?.filter(item => item.key === "amenities")?.[0]?.enumOptions
  const amenitiesOptionsArr = amenitiesOptions?.map(option => {
    return {
      key: option.option, label: option.label
    }
  })

  let selectedOptionsArr;

  if (!!publicData?.listingAmenitiesBoat?.length) {
    selectedOptionsArr = publicData?.listingAmenitiesBoat;
  }
  else if (!!publicData?.listingAmenitiesJetSki?.length) {
    selectedOptionsArr = publicData?.listingAmenitiesJetSki;
  }
  else if (!!publicData?.listingAmenitiesFishing?.length) {
    selectedOptionsArr = publicData?.listingAmenitiesFishing;
  }

  const isBoatListing = isBoatListingType(listingCategory);
  const isJetSkiListing = isJetSkiListingType(listingCategory);
  const isFishingListing = isFishingListingType(listingCategory);

  return (
    <div className={css.sectionAmenities}>
      <Heading as="h3" rootClassName={css.sectionHeading}>
        <FormattedMessage
          id={
            isBoatListing ? "ListingPage.amenitiesBoat" :
              isJetSkiListing ? "ListingPage.amenitiesJetSki" :
                isFishingListing ? "ListingPage.amenitiesFishing" :
                  "ListingPage.amenities"
          }
        />
      </Heading>
      <PropertyGroup
        id="ListingPage.amenities"
        options={amenitiesOptionsArr}
        selectedOptions={selectedOptionsArr}
        twoColumns={amenitiesOptionsArr?.length > 5}
      />
    </div>
  )
};

export default SectionAmenitiesMaybe;
