import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconMessage, AvatarLarge, InlineTextButton } from '../../components';

import css from './ListingPage.module.css';

const SectionHostBy = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    currentUser,
    isOwnListing
  } = props;

  if (!listing.author) {
    return null;
  }

  return (
    <div id="hostedBy" className={css.hostedBy}>
      <AvatarLarge className={css.hostedByAvatar} user={listing.author} />

      <div className={css.hostedByContent}>
        <p className={css.hostedByInfo}>
          <FormattedMessage id="ListingPage.hostedBy" values={{ authorDisplayName }} />
        </p>
        {!isOwnListing && (
          <InlineTextButton
            className={css.hostedByBtn}
            onClick={onContactUser}
            enforcePagePreloadFor="SignupPage"
          >
            <IconMessage />
            <FormattedMessage id="ListingPage.writeAMessage" />
          </InlineTextButton>
        )}
      </div>
    </div>
  );
};

export default SectionHostBy;
