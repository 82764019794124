import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';

import ActionBarMaybe from './ActionBarMaybe';
import classNames from 'classnames';
import css from './ListingPage.module.css';

const SectionHero = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    handleViewPhotosButtonClick,
    publicData,
    currentImageIndex,
    isMobileLayout,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const secondImage = hasImages && listing.images.length > 1 ? listing.images[1] : null;
  const thirdImage = hasImages && listing.images.length > 2 ? listing.images[2] : null;
  const fourthImage = hasImages && listing.images.length > 3 ? listing.images[3] : null;
  const fivethImage = hasImages && listing.images.length > 4 ? listing.images[4] : null;

  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith('scaled'))
    : [];

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe
        className={css.actionBarForHeroLayout}
        isOwnListing={isOwnListing}
        listing={listing}
        editParams={editParams}
      />
    </div>
  ) : null;

  // const viewPhotosButton = hasImages ? (
  //   <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
  //     <FormattedMessage
  //       id="ListingPage.viewImagesButton"
  //       values={{ count: listing.images.length }}
  //     />
  //   </button>
  // ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosButtonClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionHero} data-testid="hero">
      {actionBar}
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          <ResponsiveImage
            rootClassName={secondImage ? css.rootForImage : css.singleRootForImage}
            alt={title}
            slideindex={0}
            image={firstImage}
            variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
          />
          {secondImage && !isMobileLayout ? (
            <div className={classNames(
              css.otherImageContainer,
              { [css.otherImageContainerTwo]: hasImages && listing.images.length > 1 },
              { [css.otherImageContainerThree]: hasImages && listing.images.length > 2 },
              { [css.otherImageContainerFour]: hasImages && listing.images.length > 3 },
              { [css.otherImageContainerFive]: hasImages && listing.images.length > 4 }
            )}>

              {secondImage && (
                <ResponsiveImage
                  rootClassName={css.image}
                  alt={title}
                  slideindex={1}
                  image={secondImage}
                  variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
                />
              )}
              {thirdImage && (
                <ResponsiveImage
                  rootClassName={css.image}
                  alt={title}
                  slideindex={2}
                  image={thirdImage}
                  variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
                />
              )}
              {fourthImage && (
                <ResponsiveImage
                  rootClassName={css.image}
                  alt={title}
                  slideindex={3}
                  image={fourthImage}
                  variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
                />
              )}
              {fivethImage && (
                <ResponsiveImage
                  rootClassName={css.image}
                  alt={title}
                  slideindex={4}
                  image={fivethImage}
                  variants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
                />
              )}
            </div>
          ) : null}
          {viewPhotosButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel
          images={listing.images}
          currentImageIndex={currentImageIndex}
          imageVariants={['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge']}
        />
      </Modal>
    </div>
  );
};

export default SectionHero;
